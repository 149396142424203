
import axios from 'axios';
export default {
    
    async createDrivers(params)  {
        return await axios.post(`drivers/create` , params)
    },
    async createDriversList(params)  {
        return await axios.post(`drivers/create/list` , params)
    },
    async updateDriversColumn(column , value , data)  {
        return await axios.put(`drivers/update_list?${column}=${value}` , data)
    },
    async deleteDriversList(list)  {
        return await axios.delete(`drivers/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportDrivers(column , value)  {
        return await axios.get(`drivers/report?${column}=${value}`)
    },
    async getAllDrivers()  {
        return await axios.get(`drivers/all`)
    },
    async getOneDrivers(driver_id)  {
        return await axios.get(`drivers/all/${driver_id}`)
    },
    async getDriversByColumn(column , value)  {
        return await axios.get(`drivers/filter?column=${column}&value=${value}`)
    },
    async deleteDrivers(driver_id)  {
        return await axios.delete(`drivers/delete/${driver_id}`)
    },
    async updateDrivers(driver_id , params)  {
        return await axios.put(`drivers/update/${driver_id}` , params)
    }
}